<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="剧名" prop="videoName">
                <a-input v-model="queryParam.videoName" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="ID" prop="id">
                <a-input v-model="queryParam.id" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="分类" prop="tagIds">
                <a-select v-model="queryParam.tagIds" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(tag,index) in tagList" :key="index" :value="tag.id" >
                    {{tag.tagTitle}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="标签" prop="type">
                <a-select v-model="queryParam.type" placeholder="请选择" allow-clear>
                  <a-select-option value="0">无标签</a-select-option>
                  <a-select-option value="1">NEW</a-select-option>
                  <a-select-option value="2">HOT</a-select-option>
                  <a-select-option value="3">首播</a-select-option>
                  <a-select-option value="4">独播</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="上映状态" prop="isRelease">
                <a-select v-model="queryParam.isRelease" placeholder="请选择" allow-clear>
                  <a-select-option value="0">否</a-select-option>
                  <a-select-option value="1">是</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
            <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['video:video:add']">
          <a-icon type="plus" />新增
        </a-button>
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['video:video:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['video:video:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="dashed" @click="$refs.importExcel.importExcelHandleOpen()">-->
<!--          <a-icon type="import" />导入-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['video:video:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :scroll="{ x: 2500 }"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <img slot="coverUrl" slot-scope="text, record" style="width: 48px;height: 48px;" :preview="record.coverUrl" v-image-preview :src="record.coverUrl">
        <span slot="sort" slot-scope="text, record">
          <a-input-number :min="0" :precision="0" @keyup.enter="editSort(record)" v-model="record.sort"></a-input-number>
        </span>
        <span slot="trendingSort" slot-scope="text, record">
          <a-input-number :min="0" :precision="0" @keyup.enter="editTrendingSort(record)" v-model="record.trendingSort"></a-input-number>
        </span>

        <span slot="isOpen" slot-scope="text, record">
          <a-switch checkedChildren="是" unCheckedChildren="否" v-model="record.isOpen == 1" @change="changeIsOpen($event,record)"/>
        </span>

        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['video:video:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['video:video:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" />
          <a @click="$refs.Gather.getLists(record.id)">
            <a-icon type="search" />剧集管理
          </a>


          <a @click="$refs.VideoPerformer.getVideoPerformer(record.id)">
            <a-icon type="search" />演员列表
          </a>


          <a-divider type="vertical" v-hasPermi="['video:video:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['video:video:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
        :rowKey="(record, index) => index + 1"
      />
    </a-card>
    <gather ref="Gather"></gather>
    <VideoPerformer ref="VideoPerformer"></VideoPerformer>
  </page-header-wrapper>
</template>

<script>
import { pageVideo,editSort,editTrendingSort, delVideo ,changeIsOpen} from '@/api/video/video'
import { listTag} from '@/api/video/tag'
import CreateForm from './modules/CreateForm'
import VideoPerformer from './videoPerformer/index'
import Gather from '../gather/index'
import { tableMixin } from '@/store/table-mixin'
import ImportExcel from './modules/ImportExcel'

export default {
  name: 'Video',
  components: {
    CreateForm,
    VideoPerformer,
    Gather,
    ImportExcel
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: true,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      tagList: [],
      total: 0,
      // 查询参数
      queryParam: {
        id: null,
        lookNum: null,
        limitNum: null,
        diamondPrice: null,
        goldPrice: null,
        describe: null,
        tagIds: null,
        type: null,
        isRelease: null,
        totalNum: null,
        videoName: null,
        coverUrl: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: '50',
          align: 'center'
        },
        {
          title: '剧名',
          dataIndex: 'videoName',
          width: '200',
          align: 'center'
        },
        {
          title: '是否启用',
          dataIndex: 'isOpen',
          align: 'center',
          scopedSlots: { customRender: 'isOpen' }
        },
        {
          title: '排序(从小到大,按下回车键保存)',
          dataIndex: 'sort',
          align: 'center',
          width: '120',
          scopedSlots: { customRender: 'sort' }
        },
        {
          title: 'trending排序(从小到大,按下回车键保存)',
          dataIndex: 'trendingSort',
          align: 'center',
          width: '120',
          scopedSlots: { customRender: 'trendingSort' }
        },
        {
          title: '封面图',
          dataIndex: 'coverUrl',
          align: 'center',
          width: '180',
          scopedSlots: { customRender: 'coverUrl' }
        },
        {
          title: '总集数',
          dataIndex: 'totalNum',
          width: '100',
          align: 'center'
        },

        // {
        //   title: '前多少集可以使用金币观看',
        //   dataIndex: 'limitNum',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        {
          title: '每集金币价格',
          dataIndex: 'diamondPrice',
          width: '100',
          align: 'center'
        },

        {
          title: '标签',
          dataIndex: 'type',
          width: '100',
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '无标签'
            }else if(t == 1) {
              return 'NEW'
            }else if(t == 2) {
              return 'Hot'
            }else if(t == 3) {
              return '首播'
            }else if(t == 4) {
              return '独播'
            }
          }
        },
        // {
        //   title: '每集金币价格',
        //   dataIndex: 'goldPrice',
        //   width: '10%',
        //   align: 'center'
        // },
        {
          title: '上映时间',
          dataIndex: 'releaseTime',
          // ellipsis: true,,
          width: '240',
          align: 'center'
        },
        {
          title: '分类',
          dataIndex: 'tagIds',
          width: '150',
          align: 'center'
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   // ellipsis: true,,
        //   align: 'center'
        // },
        // {
        //   title: '观看数',
        //   dataIndex: 'lookNum',
        //   width: '120',
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          ellipsis: true,
          fixed: 'right',
          width: '250',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.getTagList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getTagList() {
      listTag().then(response => {
        this.tagList = response.data
      })
    },
    /** 查询短剧列表 */
    getList () {
      this.loading = true
     pageVideo(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    editSort(record) {
      editSort({ id: record.id,sort: record.sort}).then(response => {
        this.$message.success(
          '操作成功',
          3
        )
      })
    },

    changeIsOpen (e,record) {
      record.isOpen = e ? 1 : 0
      changeIsOpen({id: record.id, isOpen: record.isOpen}).then(response => {
        this.$message.success(
          '操作成功'
        )
        return  record.isOpen = e ? 1 : 0
      })
    },
    editTrendingSort(record) {
      editTrendingSort({ id: record.id,trendingSort: record.trendingSort}).then(response => {
        this.$message.success(
          '操作成功',
          3
        )
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        lookNum: undefined,
        limitNum: undefined,
        diamondPrice: undefined,
        goldPrice: undefined,
        describe: undefined,
        tagIds: undefined,
        totalNum: undefined,
        videoName: undefined,
        coverUrl: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delVideo(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('video/video/export', {
            ...that.queryParam
          }, `短剧_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
